<template>
  <div id="main">
    <div class="container">
      <div class="leftItem">
        <img class="img1" src="../assets/guitar.png" />
      </div>
      <div class="CenterItem">
        <h1>האולפן ביסעור</h1>
      </div>
      <div class="rightItem">
        <a href="/"><img class="img" src="../assets/logo.png" /></a>
      </div>
    </div>

    <div class="topnav">
      <router-link to="/">בית</router-link>
      <router-link to="/productions">הפקות</router-link>
      <router-link to="/recommendation">המלצות</router-link>
      <router-link to="/learn">לימודי סאונד</router-link>
      <router-link to="/contact">צרו קשר</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteHeader",
  created() {
    console.log("created");
  },
};
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: center;
  padding: 15px;
}

.leftItem {
  width: 40%;
  text-align: right;
}

.rightItem {
  width: 40%;
  text-align: left;
}

.CenterItem {
  margin: auto;
}
.img {
  max-width: 200px;
  height: auto;
}

.img1 {
  max-width: 150px;
  height: auto;
  border-radius: 50%;
  margin-right: 30px;
  box-shadow: 2px 2px 14px white;
}

h1 {
  font-size: 5rem;
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: rgb(184, 180, 180);
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: center;
  color: #f2f2f2;
  text-align: center;
  padding: 25px 25px;
  text-decoration: none;
  font-size: 35px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: white;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04aa6d;
  color: white;
}

@media (max-width: 700px) {
  .container {
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .img {
    max-width: 100%;
    height: auto;
  }

  .topnav a {
    padding: 5px 5px;
  }

  .img1 {
    max-width: 60px;
    height: auto;
    border-radius: 50%;
    box-shadow: 2px 2px 14px white;
  }

  .leftItem {
    width: 20%;
    text-align: left;
  }

  .CenterItem {
    width: 60%;
    text-align: center;
    vertical-align: center;
  }

  .rightItem {
    width: 20%;
    text-align: right;
    align-content: right;
  }

  .topnav a {
    font-size: 18px;
  }
}
</style>