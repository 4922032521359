<template>
  <div class="container">
    <section class="footer">
      <h3>About Us</h3>
      <div class="rightItem">
      אולפן הקלטות ביסעור
      <br />
      054-7886141
      <br />
      <a href="mailto:uazulay@gmail.com?subject=About Yasur studio..."> uazulay@gmail.com</a>
    </div>
      <div class="icons">
        <a href="https://www.facebook.com/udi.azulay.16"><i class="fa fa-facebook"></i></a>
        <a href="https://www.youtube.com/playlist?list=PLXHSCk9pLf1F6zyrBmEoliLJ09kf4S5TP"><i class="fa fa-youtube"></i></a>        
        <a href="https://www.instagram.com/udi_azulay/"><i class="fa fa-instagram"></i></a>
        <a href="https://www.linkedin.com/in/udi-azulay-7770b65/"><i class="fa fa-linkedin"></i></a>      
        
      </div>
      <p>Made with <i class="fa fa-heart-o"></i> By Udi Azulay</p>
    </section>  
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>


<style scoped>
/* ------------- footer ------------- */

.container {
  display: flex; /* or inline-flex */
  width: 70%;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
  margin: 50px;  
  border-radius: 25px;
  margin: auto;
  margin-top: 10px;
}

.footer {
  width: 100%;
  text-align: center;
  padding: 30px 0;
}
.footer h3 {
  margin-bottom: 15px;
  margin-top: 20px;
  font-weight: 600;
}

.footer p {
  margin: 5px;
  padding: 15px;
}

.icons .fa {
  color: white;
  margin: 0 13px;
  cursor: pointer;
  padding: 18px 0;
}

.fa-heart-o {
  color: #f44336;
}

@media (max-width: 700px) {
  .container {
    width: 90%;
    display: flex; /* or inline-flex */
    flex-direction: column;
    justify-content: center;    
    border-radius: 25px;
  }
}
</style>