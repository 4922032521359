<template>
  <div class="home">
    <div class="text-box">
      <h1>אז מה יש לאולפן להציע לך?</h1>
      <br />
      <h4>
        הפקת שיר מקורי (סינגל) משלב הסקיצה ועד לעיבוד מלא כם כל כלי הנגינה.
        הקלטת קאברים (לשירים מוכרים) , שיר במתנה , שיר בהפתעה , בר/בת מצווה ,
        ברכת כלה / חתן ועוד...
        <br /><br />
        יוצרים ? אם אתם כותבים ו/או מלחינים שירים - הגעתם למקום הנכון. באולפן
        ההקלטות שביסעור תוכלו לבנות עיבודים מההתחלה לשירים המקוריים שיצרתם.
        <br /><br />
        הפקה מוזיקלית עם כל הנשמה!
        <br /><br />

        ולא פחות חשוב - עם ציוד סאונד מקצועי מהאקורד הראשון ועד לתו האחרון.
        הקלטות שירה / גיטרות / כלי מיתר / תופים / ועוד.
        <br /><br />
        תוכל להתרשם <router-link to="/productions">"בהפקות"</router-link> שעובדו
        והוקלטו פה באולפן. אז מחכים
        <router-link to="/contact">שתצרו קשר</router-link> <br /><br />
         <img class="img" src="../assets/StudioA.jpg" />
      <img class="img" src="../assets/StudioB.jpg" />
      <img class="img" src="../assets/StudioC.jpg" />
      <img class="img" src="../assets/StudioD.jpg" />
      </h4>
     
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  components: {},
  methods: {},
  created() {},
});
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: center;
  padding: 15px;
  margin: 20px;      
  margin-top: 10px;
}

.img {
  max-width: 100%;
  height: auto;
}

.text-box {
  width: 90%;
  color: #fff;  
  padding: 10px;
  margin-top: 20px;
}

.text-box h1 {
  font-size: 2.5rem;
}

.text-box h4 {
  font-size: 1.5rem;
}

.home {
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.8), rgba(4, 9, 30, 0.2)), url(../assets/studio1.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}
 
@media (max-width: 700px) {
  .text-box h1 {
    font-size: 1.5rem;
  }

  .container {
    display: flex; /* or inline-flex */   
    padding: 5px;
    margin: 10px;       
  }

  .text-box h4 {
    font-size: 1rem;
    text-align: right;
  }
}
</style>

