<template>
  <site-header></site-header> 
  <router-view />
  <site-footer></site-footer>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";

export default {
  components: {
    SiteHeader,
    SiteFooter
  } 
};
</script>

<style scoped>
.phone {
  color: white;
}

</style>

<style lang="scss">
#app {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
</style>
