import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

require("./assets/css/main.scss");

/*eslint no-undef: "off"*/
ga('set', 'page', router.currentRoute.path);
ga('send', 'pageview');

router.afterEach((to, from) => {
    /*eslint no-unused-vars : "off"*/
    ga('set', 'page', to.path);
    ga('send', 'pageview');
});


createApp(App).use(store).use(router).mount('#app')